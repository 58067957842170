<template>
    <div
        class="record-materials-modal-group-orders-material__container"
        @click="handleOpenMaterial"
    >
        <div class="record-materials-modal-group-orders-material__content">
            <span class="record-materials-modal-group-orders-material__label">
                {{ material.name }}
            </span>
        </div>

        <div class="record-materials-modal-group-orders-material__arrow-right-icon">
            <ArrowRight />
        </div>
    </div>
</template>

<script>
    import { BFormGroup, BFormInput } from "bootstrap-vue";
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import ArrowRight from "@core/assets/icons/arrow-icon.svg";

    export default {
        components: { BFormGroup, BFormInput, ArrowRight },
        props: {
            material: {
                type: Object,
                required: true,
                default: () => ({
                    id: -1,
                    name: "",
                    quantityConsumed: 0
                })
            }
        },
        computed: {
            ...mapGetters("production_progress", ["recordMaterialsModal"])
        },
        methods: {
            ...mapActions("production_progress", ["openOrdersGroupMaterialModal"]),
            ...mapMutations("production_progress", [
                "UPDATE_OPERATIONS_MODAL_MATERIAL",
                "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL"
            ]),
            handleOpenMaterial() {
                this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL({
                    ...this.material
                });
                this.openOrdersGroupMaterialModal();
            }
        }
    };
</script>

<style lang="scss" scoped>
    #record-materials-modal {
        .record-materials-modal-group-orders-material__container {
            cursor: pointer;
            padding: 31.5px 29px;
            display: flex;
            flex-wrap: nowrap;
            gap: 16px;
            align-items: center;
            border-bottom: 1px solid #cfc4be;

            .record-materials-modal-group-orders-material__content {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;

                .record-materials-modal-group-orders-material__label {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    min-width: 236px;
                    max-width: 236px;
                }
            }

            .record-materials-modal-group-orders-material__arrow-right-icon {
                cursor: pointer;
                display: flex;

                svg {
                    min-width: 24px;
                    max-width: 24px;
                    min-height: 24px;
                    max-height: 24px;
                    fill: #4c4541;
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #record-materials-modal {
            .record-materials-modal-group-orders-material__container {
                .record-materials-modal-group-orders-material__content {
                    .record-materials-modal-group-orders-material__label {
                        font-size: 22px !important;
                        line-height: 30px !important;
                        width: 100%;
                        max-width: 316px !important;
                        min-width: fit-content !important;
                    }
                }
            }
        }
    }

    @media (max-width: 570px) {
        #record-materials-modal {
            .record-materials-modal-group-orders-material__container {
                padding: 31.5px 16px;

                .record-materials-modal-group-orders-material__content {
                    .record-materials-modal-group-orders-material__label {
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }
                }

                .record-materials-modal-group-orders-material__arrow-right-icon {
                    cursor: pointer;

                    svg {
                        min-width: 16px !important;
                        max-width: 16px !important;
                        min-height: 16px !important;
                        max-height: 16px !important;
                    }
                }
            }
        }
    }
</style>
