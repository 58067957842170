<template>
    <div class="record-materials-modal-content__container">
        <Materials v-if="recordMaterialsModal.selectedTab === 'materials'" />
        <Barcode v-if="recordMaterialsModal.selectedTab === 'barcode'" />
        <History v-if="recordMaterialsModal.selectedTab === 'history'" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Barcode from "./Barcode.vue";
import History from "./History.vue";
import Materials from "./Materials.vue";

export default {
    components: {
        Barcode,
        History,
        Materials,
    },
    computed: {
        ...mapGetters("production_progress", ["recordMaterialsModal"]),
    },
};
</script>

<style lang="scss" scoped>
#record-materials-modal {
    .record-materials-modal-content__container {
        width: 100%;
        height: 100%;
        display: flex;
    }
}
</style>
