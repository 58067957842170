<template>
    <div class="record-materials-modal-material__container">
        <div class="record-materials-modal-material__content">
            <div class="record-materials-modal-material__material">
                <span class="record-materials-modal-material__label"> Material </span>
                <span class="record-materials-modal-material__material-name">
                    {{ material.name }}
                </span>
            </div>

            <div class="record-materials-modal-material__consumed-quantity">
                <span class="record-materials-modal-material__label">
                    {{ $t("ProductionProgress.ConsumedQuantity") }}
                </span>

                <div class="record-materials-modal-material__quantity-box">
                    <b-form-group :invalid-feedback="$t('RequiredField')">
                        <b-form-input
                            type="text"
                            class="record-materials-modal-material__quantity-input"
                            :value="material.quantityConsumed"
                            :state="material.valid"
                            placeholder="0,00"
                            @input="updateConsumedQuantity"
                            :formatter="handleFormatterQuantityConsumed"
                            @blur="validateQuantityConsumed"
                        />
                    </b-form-group>
                </div>
            </div>
        </div>

        <div
            class="record-materials-modal-material__close-button-container"
            @click="handleOpenRemoveMaterialModal(material)"
        >
            <close-icon />
        </div>
    </div>
</template>

<script>
    import { BFormGroup, BFormInput } from "bootstrap-vue";
    import { mapGetters, mapMutations } from "vuex";
    import CloseIcon from "@core/assets/formatted-icons/circle-wrong.svg";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { filterMaterials } from "@/views/pages/operator/components/RecordMaterialsModal/utils";

    export default {
        components: { BFormGroup, BFormInput, CloseIcon, ToastificationContent },
        props: {
            material: {
                type: Object,
                required: true,
                default: () => ({
                    id: -1,
                    name: "",
                    quantityConsumed: 0
                })
            }
        },
        computed: {
            ...mapGetters("production_progress", ["recordMaterialsModal", "flags"])
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("production_progress", [
                "UPDATE_RECORD_MATERIALS_MODAL_MATERIALS",
                "UPDATE_RECORD_MATERIALS_MODAL_HAS_CHANGES",
                "UPDATE_RECORD_MATERIALS_MODAL_MATERIALS",
                "UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST"
            ]),
            handleFormatterQuantityConsumed(value) {
                const hasMaterial = this.recordMaterialsModal.materials.some(
                    (material) => material.id === this.material.id
                );

                if (!hasMaterial || value === "0,0" || !value) return "";

                return this.formatCurrencyWithouSymbol(value);
            },
            handleFormartQuantityConsumedToNumber(value) {
                const replacedValue = value.replace(/[^\d]/g, "");

                const onlyDigits = replacedValue
                    .split("")
                    .filter((s) => /\d/.test(s))
                    .join("")
                    .padStart(3, "0");

                if (isNaN(onlyDigits)) {
                    return;
                }

                const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);

                return Number(digitsFloat);
            },
            formatCurrencyWithouSymbol(value) {
                const numberValue = this.handleFormartQuantityConsumedToNumber(value);

                let formattedValue = new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numberValue);

                return formattedValue;
            },
            validateQuantityConsumed() {
                const materialsLocal = [...this.recordMaterialsModal.materials];
                const materialIndex = materialsLocal.findIndex(
                    (material) => material.id === this.material.id
                );

                if (materialIndex === -1) {
                    return;
                }

                if (this.material.required && !this.material.quantityConsumed) {
                    materialsLocal[materialIndex].valid = false;
                } else {
                    materialsLocal[materialIndex].valid = true;
                }

                this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS(materialsLocal);
            },
            handleOpenRemoveMaterialModal(material) {
                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    text:
                        this.$t("ProductionProgress.RemoveNoteAlertPart1") +
                        (material.name || "") +
                        this.$t("ProductionProgress.RemoveNoteAlertPart2"),
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("MySites.cancel"),
                    confirmButtonText: this.$t("ResourceStatus.Delete"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (!result.value) return;

                    this.handleRemoveMaterial(material);
                });
            },
            async handleRemoveMaterial(material) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.removeMaterial(material);
                    const materialsResponse = await this.getMaterials();

                    const newMatarials = this.flags.isMultipleOrders
                        ? materialsResponse
                        : this.handlePersistFilledData(
                              this.recordMaterialsModal.materials,
                              materialsResponse
                          );

                    this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS([...newMatarials]);
                    filterMaterials(
                        this.recordMaterialsModal.filterInput,
                        newMatarials,
                        this.UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST
                    );
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async removeMaterial(material) {
                const { order_id } = this.$router.currentRoute.params;
                const DELETE_MATERIAL_URL = `/api/mysfc/operation/${order_id}/material/${material.id}/remove`;

                try {
                    const response = await this.$http2.delete(DELETE_MATERIAL_URL);
                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t("ProductionProgress.MaterialRemoved"),
                        "success"
                    );
                    return Promise.resolve(response);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            async getMaterials() {
                const { order_id } = this.$router.currentRoute.params;
                const GET_MATERIALS_URL = `/api/mysfc/operation/${order_id}/materials`;

                try {
                    const response = await this.$http2.get(GET_MATERIALS_URL);

                    const formattedMaterials =
                        response?.data?.data?.map((material) => ({
                            ...material,
                            valid: null,
                            quantityConsumed: material.quantityConsumed?.toString() ?? ""
                        })) || [];

                    return Promise.resolve(formattedMaterials);
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            handlePersistFilledData(oldList, newList) {
                const formattedNewList =
                    newList?.map((newMaterial) => {
                        const itemInOldList = oldList.find(
                            (oldMaterial) => oldMaterial.id === newMaterial.id
                        );

                        if (itemInOldList) return { ...itemInOldList };

                        return newMaterial;
                    }) || [];

                return formattedNewList;
            },
            updateConsumedQuantity(value) {
                const materialsLocal = [...this.recordMaterialsModal.materials];
                const materialIndex = materialsLocal.findIndex(
                    (material) => material.id === this.material.id
                );

                if (materialIndex === -1) {
                    return;
                }

                materialsLocal[materialIndex].quantityConsumed = value;
                this.UPDATE_RECORD_MATERIALS_MODAL_HAS_CHANGES(true);
                this.UPDATE_RECORD_MATERIALS_MODAL_MATERIALS(materialsLocal);
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        variant
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    #record-materials-modal {
        .record-materials-modal-material__quantity-box {
            position: relative;

            .form-group {
                margin-bottom: 0;
            }

            .invalid-feedback {
                text-align: start !important;
            }

            .invalid-feedback {
                position: absolute;
                top: 100%;
            }
        }
        .record-materials-modal-material__quantity-input {
            text-align: center;
        }
    }
    @media (max-width: 768px) and (min-width: 571px) {
        #record-materials-modal {
            .record-materials-modal-material__quantity-input {
                height: 50px !important;
            }
        }
    }
</style>

<style lang="scss" scoped>
    #record-materials-modal {
        .record-materials-modal-material__container {
            padding: 31.5px 29px;
            display: flex;
            flex-wrap: nowrap;
            gap: 16px;
            align-items: center;
            border-bottom: 1px solid #cfc4be;

            .record-materials-modal-material__content {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;

                .record-materials-modal-material__material,
                .record-materials-modal-material__consumed-quantity {
                    width: 100%;
                    display: flex;
                    gap: 16px;
                }

                .record-materials-modal-material__label {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    min-width: 236px;
                    max-width: 236px;
                }

                .record-materials-modal-material__material {
                    align-items: flex-start;
                    .record-materials-modal-material__material-name {
                        color: #4c4541;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                    }
                }

                .record-materials-modal-material__consumed-quantity {
                    align-items: center;

                    .record-materials-modal-material__quantity-box {
                        width: 100%;
                        color: #4c4541;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }

            .record-materials-modal-material__close-button-container {
                padding: 12px;
                cursor: pointer;

                svg {
                    min-height: 14px;
                    max-height: 14px;
                    min-width: 14px;
                    max-width: 14px;
                    fill: #d32f2f;
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #record-materials-modal {
            .record-materials-modal-material__container {
                .record-materials-modal-material__content {
                    .record-materials-modal-material__label {
                        font-size: 22px !important;
                        line-height: 30px !important;
                        width: 100%;
                        max-width: 316px !important;
                        min-width: fit-content !important;
                    }

                    .record-materials-modal-material__material {
                        .record-materials-modal-material__material-name {
                            font-size: 22px !important;
                            line-height: 30px !important;
                        }
                    }
                }

                .record-materials-modal-material__close-button-container {
                    padding: 14px !important;

                    svg {
                        min-width: 18px !important;
                        max-width: 18px !important;
                        min-height: 18px !important;
                        max-height: 18px !important;
                    }
                }
            }
        }
    }

    @media (max-width: 570px) {
        #record-materials-modal {
            .record-materials-modal-material__container {
                padding: 23.5px 16px;

                &:last-child {
                    padding: 24px 16px;
                }

                .record-materials-modal-material__content {
                    .record-materials-modal-material__material,
                    .record-materials-modal-material__consumed-quantity {
                        flex-direction: column;
                        gap: 4px;
                        align-items: flex-start;
                    }

                    .record-materials-modal-material__label {
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }

                    .record-materials-modal-material__material {
                        align-items: flex-start;
                        .record-materials-modal-material__material-name {
                            font-size: 14px !important;
                            line-height: 20px !important;
                        }
                    }
                }
            }
        }
    }
</style>
