<template>
    <div
        :class="['record-materials-modal-header__option', { active }]"
        @click="handleClick"
    >
        <span>{{ label }}</span>
        <div class="active-line" v-if="active" />
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
        },
        active: {
            type: Boolean,
        },
    },
    methods: {
        handleClick() {
            this.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
#record-materials-modal {
    .record-materials-modal-header__option {
        position: relative;
        padding: 15.5px 16px;
        border-bottom: 1px solid #cfc4be;
        display: flex;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        width: 100%;
        background: transparent;

        span {
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            color: #998f8a;
            white-space: nowrap;
        }

        &.active {
            span {
                color: #974900 !important;
            }

            .active-line {
                display: block !important;
            }
        }

        &:hover {
            .active-line {
                display: block !important;
            }

            span {
                color: #974900 !important;
            }
        }

        .active-line {
            display: none;
            position: absolute;
            left: 0;
            bottom: -1px;
            height: 5px;
            background: #974900;
            width: 100%;
            z-index: 1;
        }
    }
}
</style>
