<template>
    <div class="record-materials-modal-history__container">
        <material-history-card
            v-for="(register, index) in recordMaterialsModal.history"
            :key="`register-${index}`"
            :register="register"
        />
    </div>
</template>

<script>
    import MaterialHistoryCard from "@/views/pages/operator/components/MaterialHistoryCard";
    import { mapGetters, mapMutations } from "vuex";
    import moment from "moment";

    export default {
        components: { MaterialHistoryCard },
        data: () => ({}),
        computed: {
            ...mapGetters("production_progress", ["recordMaterialsModal"])
        },
        methods: {
            ...mapMutations("production_progress", ["UPDATE_RECORD_MATERIALS_MODAL_HISTORY"]),
            sortHistoryByDate(a, b) {
                const dataA = moment(a.actionTime);
                const dataB = moment(b.actionTime);

                if (dataA.isBefore(dataB)) {
                    return 1; // dataA vem antes que dataB
                }
                if (dataA.isAfter(dataB)) {
                    return -1; // dataA vem depois que dataB
                }
                return 0; // As datas são iguais
            }
        }
    };
</script>

<style lang="scss" scoped>
    #record-materials-modal {
        .record-materials-modal-history__container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding: 24px 29px;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
            }
        }
    }

    @media (max-width: 570px) {
        #record-materials-modal {
            .record-materials-modal-history__container {
                padding: 24px 16px !important;
            }
        }
    }
</style>
