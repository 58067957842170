<template>
    <div class="record-materials-modal__container">
        <div v-if="recordMaterialsModal.listScannedMaterials.length">
            <Material
                v-for="(
                    material, index
                ) in recordMaterialsModal.listScannedMaterials"
                :key="`material-${index}`"
                :material="material"
            />
        </div>
        <div v-else class="barcode-container">
            <BarCodeIcon />
            <div class="title">
                {{ $t("Operator.Barcode.Materials.Title") }}
            </div>
            <div class="subtitle">
                {{ $t("Operator.Barcode.Materials.Subtitle") }}
            </div>
        </div>
    </div>
</template>

<script>
import BarCodeIcon from "@/assets/images/icons/barcode-icon.svg";
import Material from "./Material.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
    components: {
        BarCodeIcon,
        Material,
    },
    data: () => ({
        barcode: "",
        cleanBarCodeTimeout: null,
    }),
    mounted() {
        window.addEventListener("keydown", this.handleKeydown);
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeydown);
    },
    methods: {
        ...mapMutations("production_progress", [
            "UPDATE_SCANNED_MATERIALS_MODAL_BAR_CODE",
        ]),
        readBarcode() {
            if (this.barcode.length) {
                this.getMaterial(this.barcode);
                this.$nextTick(() => {
                    this.barcode = "";
                });
            }
        },
        getMaterial(materialNumber) {
            let newArr = this.recordMaterialsModal.listScannedMaterials;
            newArr.push({
                id: 44,
                name: "nome material 1",
                quantityRequired: 10,
                required: true,
                quantityConsumed: 8,
                invalid: false,
            });
            this.UPDATE_SCANNED_MATERIALS_MODAL_BAR_CODE(newArr);
        },
        handleKeydown(event) {
            const { key } = event;

            if (key.length === 1)
                this.barcode = this.barcode ? this.barcode + key : key;
            event.key === "Enter" && this.readBarcode();
        },
        clearBarCode() {
            this.barcode = "";
        },
    },
    computed: {
        ...mapGetters("production_progress", ["recordMaterialsModal"]),
    },
    watch: {
        barcode(v) {
            clearTimeout(this.cleanBarCodeTimeout);

            if (v)
                this.cleanBarCodeTimeout = setTimeout(
                    () => this.clearBarCode(),
                    1000
                );
        },
    },
};
</script>

<style lang="scss" scoped>
#record-materials-modal {
    .record-materials-modal__container {
        width: 100%;
        .barcode-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;

            svg {
                margin-bottom: 40px;
                width: 80px;
                height: 54px;
            }

            .title,
            .subtitle {
                font-family: "Raleway";
                font-style: normal;
                text-align: center;
            }

            .title {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                color: #4c4541;
                margin-bottom: 8px !important;
            }

            .subtitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #998f8a;
            }
        }
    }
}

@media (max-width: 480px) {
    #record-materials-modal {
        .record-materials-modal__container {
            padding: 24px 16px;
            .barcode-container {
                .title {
                    font-size: 16px;
                    line-height: 26px;
                }

                .subtitle {
                    font-size: 12px;
                    line-height: 16px;
                }

                svg {
                    width: 50px;
                    height: 33.33px;
                    margin-bottom: 16.66px;
                }
            }
        }
    }
}
</style>
