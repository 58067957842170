<template>
    <div
        :class="[
            'record-materials-modal',
            {
                '--open': open,
            },
        ]"
    >
        <b-sidebar
            id="record-materials-modal"
            :visible="open"
            shadow
            right
            bg-variant="white"
            backdrop
            no-close-on-backdrop
            no-close-on-esc
        >
            <template #header>
                <Header />
            </template>

            <template>
                <Content />
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import { BSidebar, VBToggle } from "bootstrap-vue";
import Header from "./Header.vue";
import Content from "./Content.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        Header,
        Content,
        BSidebar,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    computed: {
        ...mapGetters("production_progress", ["recordMaterialsModal"]),
    },
    props: {
        open: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
    methods: {
        ...mapActions("production_progress", ["closeRecordMaterialsModal"]),
    },
};
</script>

<style lang="scss">
body:has(.record-materials-modal.--open) {
    overflow: hidden;
}

#record-materials-modal {
    min-width: 600px;
    max-width: 600px;
}

@media (max-width: 768px) {
    #record-materials-modal {
        min-width: 100dvw;
        max-width: 100dvw;
    }
}
</style>
