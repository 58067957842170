<template>
    <div class="record-materials-modal-header__wrapper">
        <div class="record-materials-modal-header__container">
            <span class="record-materials-modal-header__title">
                {{ $t("ProductionProgress.RecordMaterials") }}
            </span>

            <span
                @click="handleOnClose"
                class="record-materials-modal-header__close-icon"
            >
                <close-icon />
            </span>
        </div>

        <div class="record-materials-modal-header__options">
            <header-option
                :active="recordMaterialsModal.selectedTab === 'materials'"
                :label="$t('ProductionProgress.MaterialList')"
                @click="handleOptionClick('materials')"
            />
            <header-option
                :active="recordMaterialsModal.selectedTab === 'barcode'"
                :label="$t('ProductionProgress.Barcode')"
                @click="handleOptionClick('barcode')"
            />
            <header-option
                :active="recordMaterialsModal.selectedTab === 'history'"
                :label="$t('ProductionProgress.History')"
                @click="handleOptionClick('history')"
            />
        </div>
    </div>
</template>

<script>
import { BSidebar, VBToggle } from "bootstrap-vue";
import HeaderOption from "./HeaderOption.vue";
import CloseIcon from "@core/assets/formatted-icons/close-icon.svg";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    components: {
        BSidebar,
        HeaderOption,
        CloseIcon,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    computed: {
        ...mapGetters("production_progress", ["recordMaterialsModal"]),
    },
    methods: {
        ...mapActions("production_progress", ["closeRecordMaterialsModal"]),
        ...mapMutations("production_progress", [
            "UPDATE_ORDERS_GROUP_MATERIAL_MODAL_SELECTED_TAB",
        ]),
        handleOptionClick(option) {
            this.UPDATE_ORDERS_GROUP_MATERIAL_MODAL_SELECTED_TAB(option);
        },
        handleOnClose() {
            this.closeRecordMaterialsModal();
        },
    },
};
</script>

<style lang="scss">
#record-materials-modal {
    .b-sidebar-header {
        padding: 0 !important;
    }
}
</style>

<style lang="scss" scoped>
#record-materials-modal {
    .record-materials-modal-header__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .record-materials-modal-header__container {
            display: flex;
            padding: 15.5px 29px;
            border-bottom: 1px solid #cfc4be;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;
            gap: 16px;

            .record-materials-modal-header__title {
                font-size: 26px;
                font-weight: 600;
                line-height: 36px;
                color: #4c4541;
                width: 100%;
            }

            .record-materials-modal-header__close-icon {
                cursor: pointer;
                display: flex;
                height: fit-content;

                svg {
                    min-width: 16px;
                    max-width: 16px;
                    min-height: 16px;
                    max-height: 16px;
                    fill: #4c4541;
                }
            }

            .record-materials-modal-header__separator {
                width: 100%;
                height: 1px;
                background-color: #e5e5e5;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .record-materials-modal-header__options {
            overflow-x: auto;
            overflow-y: hidden;

            display: flex;
            flex-wrap: nowrap;

            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

@media (max-width: 768px) and (min-width: 571px) {
    #record-materials-modal {
        .record-materials-modal-header__title {
            font-size: 36px !important;
            line-height: 50px !important;
        }

        .record-materials-modal-header__close-icon {
            svg {
                min-width: 24px !important;
                max-width: 24px !important;
                min-height: 24px !important;
                max-height: 24px !important;
            }
        }
    }
}

@media (max-width: 570px) {
    #record-materials-modal {
        .record-materials-modal-header__container {
            padding: 15.5px 16px !important;

            .record-materials-modal-header__title {
                font-size: 18px !important;
                line-height: 26px !important;
            }

            .record-materials-modal-header__close-icon {
                svg {
                    min-width: 16px !important;
                    max-width: 16px !important;
                    min-height: 16px !important;
                    max-height: 16px !important;
                }
            }
        }
    }
}
</style>
